import React, { useEffect, useState } from "react";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/planos/styles";
import lineIcons from "../assets/images/line-icons.png";
import closeQuotesIcon from "../assets/icons/close-quotes-icon.png";
import openQuotesIcon from "../assets/icons/open-quotes-icon.png";
import { Helmet } from "react-helmet";
import { Grow, Typography, Hidden } from "@material-ui/core";
import PlansDisplayer from "../components/plans-displayer/PlansDisplayer";
import BoxesContainer from "../components/boxes-container";
import PlansSection from "../components/plans-section";

function Planos() {
  const classes = useStyles();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) return null;

  return (
    <DefaultLayout>
      <div className={classes.plansArea}>
        <Helmet>
          <title>Planos | Smart Baby Box</title>
        </Helmet>
        <Hidden smDown>
          <img src={lineIcons} alt="" className={classes.lineIcons} />
        </Hidden>
        <div className={classes.plansTopArea}>
          <div className={classes.plansContainer}>
            <PlansSection />
          </div>
        </div>
        <div className={classes.boxesArea}>
          <div className={classes.titleArea}>
            <Typography variant="h1" className={classes.boxesTitleText}>
              o que vem em cada box?
            </Typography>
            <Typography variant="h5" className={classes.boxesDescriptionText}>
              Descubra o que você vai receber a cada mês
            </Typography>
            <Grow in={true} timeout={1000}>
              <div className={classes.plansDisplayer}>
                <PlansDisplayer invertedColors />
              </div>
            </Grow>
          </div>
        </div>
        <div className={classes.planQuoteArea}>
          <img
            src={openQuotesIcon}
            alt="Abre aspas"
            className={classes.openQuotesIcon}
          />
          <Typography variant="h1" className={classes.planQuoteText}>
            nunca ajude uma criança numa tarefa que ela se sente capaz de fazer.
          </Typography>
          <Typography variant="h5" className={classes.planQuoteSubtitle}>
            Maria Montessori
          </Typography>
          <img
            src={closeQuotesIcon}
            alt="Fecha aspas"
            className={classes.closeQuotesIcon}
          />
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Planos;
