import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  plansSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: 32,
    },
  },
  bannersArea: {
    display: "flex",
    justifyContent: "spaceBetween",
    alignItems: "center",
    gap: 40,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  giftBoxBanner: {
    width: "100%",
    marginTop: 40,
  },
}));

export default useStyles;
