import { makeStyles } from "@material-ui/core";
import giftBoxBannerImage from "../../../assets/images/gift-box-banner.png";

const useStyles = makeStyles((theme) => ({
  giftBoxBanner: {
    width: "100%",
    height: 200,
    padding: 24,
    backgroundImage: `url(${giftBoxBannerImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    borderRadius: 25,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: 528,
    },
  },
  giftBoxBannerTitle: {
    fontSize: 64,
    color: theme.palette.text.primary,
    fontFamily: "Bakso Sapi",
    maxWidth: 436,
    textAlign: "center",
    lineHeight: "76.8px",
    marginLeft: 36,
    [theme.breakpoints.down("md")]: {
      fontSize: 34,
      marginLeft: 0,
    },
  },
  knowMoreButtonArea: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  knowMoreButton: {
    width: 282,
    height: 50,
    backgroundColor: `#FFF !important`,
    color: "rgba(59, 154, 213, 1)",
    fontWeight: 700,
    fontSize: 24,
  },
}));

export default useStyles;
