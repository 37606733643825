import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subscriptionTypeSelector: {
    display: "flex",
    alignItems: "center",
  },
  boxImage: {
    width: 296,
    height: 200,
    marginLeft: -150,
    // position: "absolute",
    zIndex: 100,
  },
  button: {
    width: 470,
    height: 50,
    backgroundColor: "rgba(250, 250, 250, 1) !important",
    color: theme.palette.text.secondary,
    fontSize: 24,
    fontWeight: 700,
  },
  buttonEndIcon: {
    marginLeft: 16,
  },
  buttonStartIcon: {
    marginRight: 16,
  },
  rightButton: {
    marginLeft: -90,
  },
  selectedBasicSubscriptionButton: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: "#FFF",
  },
  selectedCompleteSubscriptionButton: {
    backgroundColor: `#F2AF2F !important`,
    color: "#FFF",
  },
}));

export default useStyles;
