import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  planAdvantagesBanner: {
    flex: 1,
    width: 362,
    height: 656,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(250, 250, 250, 1)",
    borderRadius: 25,
    [theme.breakpoints.down("md")]: {
      minHeight: 798,
    },
  },
  recommendedIndicator: {
    backgroundColor: "#3B9AD5",
  },
  titleText: {
    color: "rgba(59, 154, 213, 1)",
    fontSize: 40,
    fontFamily: "Bakso Sapi",
    textAlign: "center",
  },
  advantagesArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  advantageContainer: {
    display: "flex",
    marginTop: 20,
  },
  recommendedBannerTextColor: {
    color: "#FFF !important",
  },
  checkIcon: {
    color: "rgba(87, 190, 191, 1)",
    marginTop: 6
  },
  advantageText: {
    fontSize: 24,
    fontWeight: 700,
    color: "rgba(124, 137, 153, 1)",
    marginLeft: 10,
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  priceTitleText: {
    fontWeight: 700,
    fontSize: 20,
    color: "rgba(124, 137, 153, 1)",
  },
  priceText: {
    fontWeight: 800,
    fontSize: 24,
    color: "rgba(124, 137, 153, 1)",
  },
  priceValueText: {
    color: "rgba(87, 190, 191, 1)",
    fontSize: 48,
  },
  subscribeButton: {
    width: 282,
    height: 50,
    backgroundColor: `rgba(59, 154, 213, 1) !important`,
    fontSize: 24,
    fontWeight: 700,
  },
  recommendedBannerSubscribeButton: {
    backgroundColor: "#FFF !important",
    color: "#3B9AD5"
  },
  subscriptionTypeSelectorArea: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
}));

export default useStyles;
