import { Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { PaymentTypes } from "../../constants/payment-types";
import { SubscriptionTypes } from "../../constants/subscription-types";
import SignupDialog from "../signup/signup-dialog/SignupDialog";
import CommunityBanner from "./community-banner";
import GiftBoxBanner from "./gift-box-banner";
import PlanAdvantagesBanner from "./plan-advantages-banner/PlanAdvantagesBanner";
import useStyles from "./styles";
import SubscriptionTypeSelector from "./subscription-type-selector";

function PlansSection() {
  const classes = useStyles();
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    SubscriptionTypes.Complete
  );

  function onChangeSubscriptionType(type: SubscriptionTypes): void {
    setSelectedSubscriptionType(type);
  }

  return (
    <div className={classes.plansSection}>
      <Hidden mdDown>
        <SubscriptionTypeSelector
          onChangeSubscriptionType={onChangeSubscriptionType}
          selectedSubscriptionType={selectedSubscriptionType}
        />
      </Hidden>
      <div className={classes.bannersArea}>
        <PlanAdvantagesBanner
          selectedSubscriptionType={selectedSubscriptionType}
          paymentType={PaymentTypes.SEMIANNUAL}
          onChangeSelectedSubscriptionType={onChangeSubscriptionType}
        />
        <PlanAdvantagesBanner
          recommended
          selectedSubscriptionType={selectedSubscriptionType}
          paymentType={PaymentTypes.YEARLY}
          onChangeSelectedSubscriptionType={onChangeSubscriptionType}
        />
        <PlanAdvantagesBanner
          selectedSubscriptionType={selectedSubscriptionType}
          paymentType={PaymentTypes.RECURRENCE}
          onChangeSelectedSubscriptionType={onChangeSubscriptionType}
        />
      </div>
      <div className={classes.giftBoxBanner}>
        <GiftBoxBanner />
      </div>
      <SignupDialog />
    </div>
  );
}

export default PlansSection;
