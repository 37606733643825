import { Button, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { ArrowForward as RightIcon } from "@material-ui/icons";
import { navigateToLink } from "../../../helpers/navigation-helper/navigation-helper";
import { SINGLE_SHOP_WEBSITE_URL } from "../../../constants/single-shop-website-url";

function GiftBoxBanner() {
  const classes = useStyles();
  return (
    <div className={classes.giftBoxBanner}>
      <Typography className={classes.giftBoxBannerTitle}>
        Compre para Presente
      </Typography>
      <div className={classes.knowMoreButtonArea}>
        <Button
          variant="contained"
          size="large"
          className={classes.knowMoreButton}
          endIcon={<RightIcon />}
          onClick={() => navigateToLink(SINGLE_SHOP_WEBSITE_URL)}
        >
          Saiba Mais
        </Button>
      </div>
    </div>
  );
}

export default GiftBoxBanner;
