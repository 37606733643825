import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import basicBoxImage from "../../../assets/images/basic-box-image.png";
import completeBoxImage from "../../../assets/images/complete-box-image.png";
import { SubscriptionTypes } from "../../../constants/subscription-types";
import { Cached as ReloadIcon } from "@material-ui/icons";
import classNames from "classnames";

interface SubscriptionTypeSelectorProps {
  onChangeSubscriptionType: Function;
  selectedSubscriptionType: SubscriptionTypes;
}

function SubscriptionTypeSelector(props: SubscriptionTypeSelectorProps) {
  const classes = useStyles();

  return (
    <div className={classes.subscriptionTypeSelector}>
      <Button
        variant="contained"
        size="large"
        className={classNames(
          classes.button,
          props.selectedSubscriptionType === SubscriptionTypes.Basic
            ? classes.selectedBasicSubscriptionButton
            : null
        )}
        classes={{
          endIcon: classes.buttonEndIcon,
        }}
        endIcon={
          props.selectedSubscriptionType !== SubscriptionTypes.Basic ? (
            <ReloadIcon />
          ) : null
        }
        onClick={() => props.onChangeSubscriptionType(SubscriptionTypes.Basic)}
      >
        Assinatura Básica
      </Button>
      <img
        src={
          props.selectedSubscriptionType === SubscriptionTypes.Basic
            ? basicBoxImage
            : completeBoxImage
        }
        className={classes.boxImage}
      />
      <Button
        variant="contained"
        size="large"
        className={classNames(
          classes.button,
          classes.rightButton,
          props.selectedSubscriptionType === SubscriptionTypes.Complete
            ? classes.selectedCompleteSubscriptionButton
            : null
        )}
        classes={{
          startIcon: classes.buttonStartIcon,
        }}
        onClick={() =>
          props.onChangeSubscriptionType(SubscriptionTypes.Complete)
        }
        startIcon={
          props.selectedSubscriptionType !== SubscriptionTypes.Complete ? (
            <ReloadIcon />
          ) : null
        }
      >
        Assinatura Completa
      </Button>
    </div>
  );
}

export default SubscriptionTypeSelector;
